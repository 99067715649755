export const COUNTRY_LIST_REQUEST = 'COUNTRY_LIST_REQUEST'
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS'
export const COUNTRY_LIST_FAIL = 'COUNTRY_LIST_FAIL'

export const CITIY_LIST_WITH_COUNTRY_REQUEST = 'CITIY_LIST_WITH_COUNTRY_REQUEST'
export const CITIY_LIST_WITH_COUNTRY_SUCCESS = 'CITIY_LIST_WITH_COUNTRY_SUCCESS'
export const CITIY_LIST_WITH_COUNTRY_FAIL = 'CITIY_LIST_WITH_COUNTRY_FAIL'

export const PAYMENT_METHOD_LIST_REQUEST = 'PAYMENT_METHOD_LIST_REQUEST'
export const PAYMENT_METHOD_LIST_SUCCESS = 'PAYMENT_METHOD_LIST_SUCCESS'
export const PAYMENT_METHOD_LIST_FAIL = 'PAYMENT_METHOD_LIST_FAIL'