import React from 'react'
import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookie from 'js-cookie'
import GlobIcon from './GlobIcon'
import SearchBox from './SearchBox'
import { logout } from '../actions/userActions'
import '../css/header.css'

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'am',
    name: 'አማርኛ',
    country_code: 'et',
  },
  {
    code: 'or',
    name: 'oromifa',
    country_code: 'et',
  },
]

const Header = () => {
  const currentLanguageCode = cookie.get('i18next') || 'en'
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const logoutHandler = () => {
    dispatch(logout())
  }
  return (
    <header className='header'>
      <Link to='/' className=''>
        <img
          src='/jeba-header.png'
          className='logo'
          alt='Jeba Mega Store'
        />
      </Link>
      {/* <form action="#" class="search">
          <input type="text" class="search__input" placeholder="Search products" />
          <button class="search__button">
              <span class="search__icon">
                <i class="fas fa-search"></i>
              </span>
          </button>
      </form> */}
       <Route render={({ history }) => <SearchBox history={history} />} />
      <nav class="user-nav">
        <Link to='/cart' className='user-nav__icon-box'>
          <i class="fas fa-shopping-cart fa-lg"></i>         
          <span class="user-nav__notification"> {cartItems.length}</span>
        </Link>
        {userInfo ? <>
          <Link to='/currentorder' className='user-nav__user'>
            My Orders
          </Link>
          <Link to='/profile' className='user-nav__user'>
            {userInfo.user.name}
          </Link>
          <div onClick={logoutHandler} className='user-nav__user'>
            <i class="fas fa-sign-out-alt fa-lg"></i>
          </div>
          {/* <div class="user-nav__user">
              <span class="user-nav__user-name m-2"> </span>
              <button onClick={logoutHandler}>
                <i class="fas fa-sign-out-alt fa-lg"></i>
              </button>
          </div> */}
        </>  : <>         
          <Link to='/login' className='user-nav__icon-box m-1'>
            <i class="fas fa-sign-in-alt fa-lg"></i> Sign In 
          </Link>
        </>}
        
    </nav>
    </header>
  )
}

export default Header
