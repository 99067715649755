import React from 'react'
import '../css/order-steps-indicator.css'

const OrderStepsIndicator = ({steps, currentStep}) => {
  return (
    <div className="order-indicator">
    {steps.map((step, index) => {
      const lineColor = index < currentStep ? 'green' : 'grey';

      return (
        <div className="order-step" key={index}>
          <div className={`circle ${index < currentStep ? 'completed' : (index === currentStep ? 'active' : '')}`}>
            {index < currentStep ? <span>&#10004;</span> : (index === currentStep ? <span>&#10004;</span> : <span>&#10004;</span>)}
          </div>
          {index < steps.length - 1 && (
            <div className="line-container">
              <div className={`line-inner ${lineColor} ${index < currentStep ? 'completed' : ''}`} />
            </div>
          )}
          <div className={`label ${index === currentStep ? 'active' : ''}`}>{step}</div>
        </div>
      );
    })}
  </div>
  )
}

export default OrderStepsIndicator