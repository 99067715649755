import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Rating from '../components/Rating'
import {
  listProductDetails,
  createProductReview,
} from '../actions/productActions'
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'
import RelatedProducts from '../components/RelatedProducts'
import { BASE_URL } from '../constants/globalConstants'

const ProductScreen = ({ history, match }) => {
  const [qty, setQty] = useState(1)
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')

  const dispatch = useDispatch()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productReviewCreate = useSelector((state) => state.productReviewCreate)
  const { success: successProductReview, error: errorProductReview } =
    productReviewCreate

  useEffect(() => {
    if (successProductReview) {
      alert('Review Submitted!')
      setRating(0)
      setComment('')
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
    }
    dispatch(listProductDetails(match.params.id))
  }, [dispatch, match, successProductReview])

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(createProductReview(match.params.id, { rating, comment }))
  }

  return (
    <>
      <Link className='btn btn-light btn-sm my-3' to='/'>
        Go Back
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <div className='row'>
            <div className='col order-1'>
              <div>
              <img
                src={`${BASE_URL}${product.image}`}
                alt={product.name}
                className='img-fluid'
              />
              </div>
            </div>
            <div className='col'>
              <div className='row'>
              <div className='col'>
              <ul className='list-group list-group-flush'>
                <li className='list-group-item'>
                  <h4>{product.name}</h4>
                </li>
                <li className='list-group-item'>
                  <Rating
                    value={product.rating}
                    text={`${
                      product.numReviews == null ? '0' : Math.round(product.numReviews)
                    } reviews`}
                  />
                </li>
                <li className='list-group-item'>
                  <p>Price: ETB {Math.floor(product.price).toLocaleString()}</p>
                </li>
                <li className='list-group-item'>
                  <p>Description: {product.description}</p>
                </li>
              </ul>
              </div>

              <div className='col'>
              <ul className='list-group list-group-flush'>
                <li className='list-group-item'>
                  <p>
                    Status:{' '}
                    {product.countInStock > 0 ? 'In Stock' : 'Out of Stock'}
                  </p>
                </li>
                {product.countInStock > 0 && (
                  <li className='list-group-item'>
                    <div className=''>Qty</div>
                    <div className='row'>
                      <div className='col'>
                        <select
                          className='form-control'
                          value={qty}
                          onChange={(e) => setQty(e.target.value)}
                        >
                          {[...Array(product.countInStock).keys()].map((x) => (
                            <option key={x + 1} value={x + 1}>
                              {x + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                )}
                <li className='list-group-item'>
                  <button
                    onClick={addToCartHandler}
                    className='btn btn-dark btn-block'
                    type='button'
                    disabled={product.countInStock === 0}
                  >
                    <i className='fas fa-cart-plus'></i> Add To Cart
                  </button>
                </li>
              </ul>
              </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col order-1'>
              <h2>Reviews</h2>
              {product.reviews.length === 0 && (
                <Message variant='info'>No Reviews</Message>
              )}
              <ul className='list-group list-group-flush'>
                {product.reviews.map((review) => (
                  <li className='list-group-item' key={review.review_id}>
                    <strong>{review.user.name}</strong>
                    <Rating value={review.rating} />
                    <p>{review.created_at.substring(0, 10)}</p>
                    <p>{review.comment}</p>
                  </li>
                ))}
                <li className='list-group-item'>
                  <h2>Write a Customer Review</h2>
                  {errorProductReview && (
                    <Message variant='danger'>{errorProductReview}</Message>
                  )}
                  {userInfo ? (
                    <form onSubmit={submitHandler}>
                      <div className='form-group'>
                        <label htmlFor='rating'>Rating</label>
                        <select
                          className='form-control'
                          value={rating}
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value=''>Select...</option>
                          <option value='1'>1 - Poor</option>
                          <option value='2'>2 - Fair</option>
                          <option value='3'>3 - Good</option>
                          <option value='4'>4 - Very Good</option>
                          <option value='5'>5 - Excellent</option>
                        </select>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='comment'>Comment</label>
                        <textarea
                          value={comment}
                          className='form-control'
                          cols='30'
                          rows='3'
                          onChange={(e) => setComment(e.target.value)}
                        >
                          {' '}
                        </textarea>
                      </div>
                      <button
                        type='submit'
                        className='btn btn-sm mt-2'
                        style={{'backgroundColor': '#123053', 'color': '#FFC200'}}
                      >
                        Submit
                      </button>
                    </form>
                  ) : (
                    <Message variant='info'>
                      Please <Link to='/login'>sign in</Link> to write a review
                    </Message>
                  )}
                </li>
              </ul>
            </div>
            <div className='col'>
                <RelatedProducts category={product.category} productId={product._id} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ProductScreen
