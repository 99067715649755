export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'

export const ONLINE_ORDER_CREATE_REQUEST = 'ONLINE_ORDER_CREATE_REQUEST'
export const ONLINE_ORDER_CREATE_SUCCESS = 'ONLINE_ORDER_CREATE_SUCCESS'
export const ONLINE_ORDER_CREATE_FAIL = 'ONLINE_ORDER_CREATE_FAIL'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST'
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS'
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL'
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET'

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST'
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS'
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL'
export const ORDER_LIST_RESET = 'ORDER_LIST_RESET'


export const CURRENT_ORDER_REQUEST = 'CURRENT_ORDER_REQUEST'
export const CURRENT_ORDER_SUCCESS = 'CURRENT_ORDER_SUCCESS'
export const CURRENT_ORDER_FAIL = 'CURRENT_ORDER_FAIL'