import React, { useEffect, useState } from 'react'
import './Slider.css'
import BtnSlider from './BtnSlider'
import dataSlider from './dataSlider'
import { useDispatch, useSelector } from 'react-redux'
import { listAdProducts } from '../../actions/productActions'
import { Link } from 'react-router-dom'
import { BASE_URL } from '../../constants/globalConstants'
import Loader from '../Loader'
import Message from '../Message'

export default function Slider() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(listAdProducts())
    }, [dispatch])

    const adProductList = useSelector((state) => state.adProductList)
    const { loading, error, adProducts } = adProductList

    const [slideIndex, setSlideIndex] = useState(1)

    const nextSlide = () => {
        if (slideIndex !== dataSlider.length) {
            setSlideIndex(slideIndex + 1)
        }
        else if (slideIndex === dataSlider.length) {
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if (slideIndex !== 1) {
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1) {
            setSlideIndex(dataSlider.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }

    return (
        <>
            {loading && <Loader />}
            {error && <Message variant='error' children={error} />}
            {adProducts !== undefined && adProducts.length > 0 && (
                <div className="container-slider">
                    {adProducts.map((adProduct, index) => (
                        <div
                            key={adProduct.ad_product_id}
                            className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                        >
                            <img
                                src={`${BASE_URL}${adProduct.product.image}`} alt={`${adProduct.product.name}`}
                            />
                        </div>
                    ))}

                    <BtnSlider moveSlide={nextSlide} direction={"next"} disable={slideIndex === adProducts.length ? true : false} />
                    <BtnSlider moveSlide={prevSlide} direction={"prev"} disable={slideIndex === 1 ? true : false} />

                    <div className='container-text'>

                        {/* <h1 className="heading-primary">
                            <span className="heading-primary--main text-white"> {adProducts[slideIndex - 1].product.name}</span>
                        </h1> */}

                        <Link to={`/product/${adProducts[slideIndex - 1].product.product_id}`} className="btn-buy btn--white btn--animated">
                            Buy Now
                        </Link>
                    </div>
                    <div className="container-dots">
                        {Array.from({ length: adProducts.length }).map((item, index) => (
                            <div
                                key={index}
                                onClick={() => moveDot(index + 1)}
                                className={slideIndex === index + 1 ? "dot active" : "dot"}
                            ></div>
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}
