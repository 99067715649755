import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { listRelatedProducts } from '../actions/productActions'
import { BASE_URL } from '../constants/globalConstants'
import Loader from './Loader'
import Message from './Message'
import Product from './Product'
import Rating from './Rating'

const RelatedProducts = ({category, productId}) => {
   const dispatch = useDispatch()

   const relatedProductList = useSelector((state) => state.relatedProductList)
   const {loading, error, relatedProducts} = relatedProductList

   useEffect(() => {
    dispatch(listRelatedProducts(category, productId))
   }, [dispatch, category, productId])
  return (
    <div>
        {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message> : (
            <div className='m-2'>
                <h4>Related Products</h4>
                {relatedProducts.map((product) => (            
                    <div key={product.product_id} className='row my-2'> 
                    <div
                    key={product.product_id}
                    className='col'
                    >
                    <div className='card card-body'>
                        <Link to={`/product/${product.product_id}`} className='row'>
                            <div className='col-md-3 col-sm-3'>
                                <img src={`${BASE_URL}${product.image}`} alt={product.name} className='img card-img-top' style={{ height: '100px', width: 'auto' }} />
                            </div>
                            <div className='col-md-9 col-sm-9'>
                                <strong>{product.name}</strong>
                                <div className='card-text'>
                                <Rating
                                    value={product.rating}
                                    text={`${
                                    product.num_of_reviews == null ? '0' : Math.round(product.num_of_reviews)
                                    } reviews`}
                                />
                                </div>
                                <p className='card-text h3'>ETB {product.price}</p>
                            </div>
                        </Link>
                    </div>
                    {/* <Product product={product} /> */}
                    </div>   
                </div>
                ))} 
            </div>
        ) }

    </div>
  )
}

export default RelatedProducts