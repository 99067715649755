import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import { addToCart, removeFromCart } from '../actions/cartActions'
import { BASE_URL } from '../constants/globalConstants'

const CartScreeen = ({ match, location, history }) => {
  const productId = match.params.id

  const qty = location.search ? Number(location.search.split('=')[1]) : 1

  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty))
    }
  }, [dispatch, productId, qty])

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id))
  }

  const checkOutHandler = () => {
    history.push('/login?redirect=shipping')
  }

  return (
    <div className='row m-2'>
      <div className='col-xl-6 order-1'>
        <h1>Shopping Cart</h1>
        {cartItems.length === 0 ? (
          <Message variant='info'>
            Your cart is empty <Link to='/'>Go Back</Link>
          </Message>
        ) : (
          <div className=''>
            {cartItems.map((item) => (
              <div className='card card-body d-flex flex-column justify-content-between align-items-start' key={item.product}>
                <img
                      src={`${BASE_URL}${item.image}`}
                      height={`150px`}
                      width={`250px`}
                      alt={item.name}
                      className='img-fluid img-rounded m-2'
                    />
                <div className='d-flex m-2 p-2'>                
                  <div className='d-flex flex-column m-2'>
                  <Link
                      to={`/product/${item.product}`}
                      className='text-dark'
                      style={{ textDecoration: 'none' }}
                    >
                      {item.name}
                    </Link>
                    <div className='paragraph'>ETB {Math.floor(item.price)
                .toFixed(2).toLocaleString()}</div>
                  </div>
                  
                </div>
                <div className='d-flex'>
                <div className='m-2 p-2'>
                      <select
                        className='form-control'
                        value={item.qty}
                        onChange={(e) =>
                          dispatch(
                            addToCart(item.product, Number(e.target.value))
                          )
                        }
                      >
                        {[...Array(item.countInStock).keys()].map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='m-2 p-2'>
                    <button
                      type='button'
                      className='btn btn-sm btn-danger'
                      onClick={() => removeFromCartHandler(item.product)}
                    >
                      <i className='fas fa-trash'></i>
                    </button>
                    </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className='col-xl-6'>
        <div className='card'>
          <ul className='list-group list-group-flush p-2'>
            <li className='list-group-item'>
              <h1>
                Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)})
                items 
              </h1>
              <h2 className='m-1'> ETB {` `} {Math.floor(cartItems
                .reduce((acc, item) => acc + item.qty * item.price, 0)
                .toFixed(2)).toLocaleString()}</h2>
            </li>
            <li className='list-group-item'>
              <button
                type='button'
                className='btn btn-dark btn-block'
                disabled={cartItems.length === 0}
                onClick={checkOutHandler}
              >
                Proceed To Checkout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default CartScreeen
