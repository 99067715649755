  import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { Link } from 'react-router-dom'
import { getOrderDetails } from '../actions/orderActions'
import { BASE_URL } from '../constants/globalConstants'
import OrderStepsIndicator from '../components/OrderStepsIndicator'

const OrderScreen = ({ match }) => {
  const orderId = match.params.id

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch(getOrderDetails(orderId))
  }, [dispatch, orderId])

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const orderSteps = ['Order Placed', 'Processing', 'Delivering', 'Delivered'];
  const currentStep = 3;

  if (!loading) {
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2)
    }
    // Calculate prices
    order.itemPrice = addDecimals(
      order.order_items.reduce(
        (acc, item) => acc + item.product.price * item.qty,
        0
      )
    )
  }

  const formatDate = (timestamp) => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(timestamp);
    date.setDate(date.getDate() + 7);
    const day = daysOfWeek[date.getDay()];
    return `${day}, ${date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })}`;
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <>
      {/* <h1>Order {order.order_id}</h1> */}
      <div className='row'>
        <div className='col-md-8'>
          <ul className='list-group list-group-flush'>        
            <li className='list-group-item'>
              <h2>Order Items</h2>
              {order.order_items.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <>
            <div className='d-flex justify-content-between align-items-center'>
              <h3>
                Arriving {formatDate(order.created_at)}
              </h3>
              <Link to={`/orders`}>
                <strong>See all orders</strong>
              </Link>
            </div>
            <div className='card card-body'>
              <ul className='d-flex flex-nowrap'>
                {order?.order_items?.map((item, index) => (
                  <>
                    <li className='list-group-item' key={index}>
                      <Link to={`/product/${item.product_id}`} className=''>
                        <img
                          // eslint-disable-next-line no-undef
                          // src={`https://www.volusion.com/blog/content/images/2021/09/Product-Photography-1.jpeg`}
                          src={`${BASE_URL}${item.product.image}`}
                          // 
                          alt={item.product.name}
                          height={`100px`}
                          width={`100px`}
                          className='img img-fluid rounded'
                        />
                      </Link>
                    </li>
                  </>
                ))}
              </ul>
              <hr />
              <div className='text-center'>
                <h4>Ordered</h4>
                <div className='mb-4'>
                  <OrderStepsIndicator steps={orderSteps} currentStep={order?.order_status?.name == 'Delivered' ? 4 : (order?.order_status?.name == 'Delivery On Progress' ? 3 : 2)} />
                </div>
                <button className='btn btn-light'>Update delivery instruction</button>
              </div>
              <hr />

              <div>
                <h5>Shipping Address</h5>
                <p>Name {userInfo?.user?.name}</p>
                <p>Phone number: {order?.shipping_address?.phone_number}</p>
                <p>Address: {order?.shipping_address?.address}</p>
              </div>

              <hr />

              <div>
                <h5>Order Info</h5>
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <Link to={`/order/${order.order_id}`}>
                      View or Change this order
                    </Link>
                    <p>{'>'}</p>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <Link to={`/order/${order.order_id}`}>
                      Cancel order
                    </Link>
                    <p>{'>'}</p>
                  </li>
                </ul>
              </div>
            </div>
          </>
              )}
            </li>
          </ul>
          <div className="d-flex flex-column align-items-center justify-content-center bg-info text-white p-3">
            <p>
              If you have any question feel free to contact us. 
            </p>
            <div className="d-flex text-white">
              <a className="nav-link text-white" href="tel:0910708570">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16"> <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/> <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/> </svg> 0910708570</a> 
            </div>
            <div className='d-flex text-white'>
              <a className='nav-link text-white' href="tel:0924898400">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16"> <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/> <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/> </svg> 0924898400</a>  
            </div>
          </div>
          
        </div>
        <div className='col-md-4'>
          <div className='card'>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item'>
                <h2>Order Summary</h2>
              </li>
              <li className='list-group-item'>
                <div className='row'>
                  <div className='col'>Items</div>
                  <div className='col'>ETB {order.itemPrice}</div>
                </div>
              </li>
              <li className='list-group-item'>
                <div className='row'>
                  <div className='col'>Shipping</div>
                  <div className='col'>ETB {order.shipping_price}</div>
                </div>
              </li>
              <li className='list-group-item'>
                <div className='row'>
                  <div className='col'>Tax</div>
                  <div className='col'>ETB {order.tax_price}</div>
                </div>
              </li>
              <li className='list-group-item'>
                <div className='row'>
                  <div className='col'>Total</div>
                  <div className='col'>ETB {order.total_price}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderScreen
