export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'

export const USER_PRODUCT_LIST_REQUEST = 'USER_PRODUCT_LIST_REQUEST'
export const USER_PRODUCT_LIST_SUCCESS = 'USER_PRODUCT_LIST_SUCCESS'
export const USER_PRODUCT_LIST_FAIL = 'USER_PRODUCT_LIST_FAIL'

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL'

export const PRODUCT_CREATE_REVIEW_REQUEST = 'PRODUCT_CREATE_REVIEW_REQUEST'
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS'
export const PRODUCT_CREATE_REVIEW_FAIL = 'PRODUCT_CREATE_REVIEW_FAIL'
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET'

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL'
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET'

export const PRODUCT_TOP_REQUEST = 'PRODUCT_TOP_REQUEST'
export const PRODUCT_TOP_SUCCESS = 'PRODUCT_TOP_SUCCESS'
export const PRODUCT_TOP_FAIL = 'PRODUCT_TOP_FAIL'

export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST'
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS'
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL'

export const SUB_CATEGORY_LIST_REQUEST = 'SUB_CATEGORY_LIST_REQUEST'
export const SUB_CATEGORY_LIST_SUCCESS = 'SUB_CATEGORY_LIST_SUCCESS'
export const SUB_CATEGORY_LIST_FAIL = 'SUB_CATEGORY_LIST_FAIL'

export const LATEST_PRODUCT_LIST_REQUEST = 'LATEST_PRODUCT_LIST_REQUEST'
export const LATEST_PRODUCT_LIST_SUCCESS = 'LATEST_PRODUCT_LIST_SUCCESS'
export const LATEST_PRODUCT_LIST_FAIL = 'LATEST_PRODUCT_LIST_FAIL'

export const RELATED_PRODUCT_LIST_REQUEST = 'RELATED_PRODUCT_LIST_REQUEST'
export const RELATED_PRODUCT_LIST_SUCCESS = 'RELATED_PRODUCT_LIST_SUCCESS'
export const RELATED_PRODUCT_LIST_FAIL = 'RELATED_PRODUCT_LIST_FAIL'

export const AD_PRODUCT_LIST_REQUEST = 'AD_PRODUCT_LIST_REQUEST'
export const AD_PRODUCT_LIST_SUCCESS = 'AD_PRODUCT_LIST_SUCCESS'
export const AD_PRODUCT_LIST_FAIL = 'AD_PRODUCT_LIST_FAIL'
