import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import CheckoutSteps from '../components/CheckoutSteps'
import { Link } from 'react-router-dom'
import { createOrder } from '../actions/orderActions'
import { BASE_URL } from '../constants/globalConstants'

const PlaceOrderScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const cart = useSelector((state) => state.cart)
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }
  // Calculate prices
  cart.itemPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  )

  cart.shippingPrice = 0
  // cart.shippingPrice = addDecimals(cart.itemPrice > 100 ? 0 : 100)

  cart.taxPrice = addDecimals(Number((0.15 * cart.itemPrice).toFixed(2)))

  cart.totalPrice = (
    Number(cart.itemPrice)
  ).toFixed(2)
  // cart.totalPrice = (
  //   Number(cart.itemPrice) +
  //   Number(cart.shippingPrice) +
  //   Number(cart.taxPrice)
  // ).toFixed(2)

  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success, error } = orderCreate

  useEffect(() => {
    if (success) {
      history.push(`/order/${order.order_id}`)
    }
    // eslint-disable-next-line
  }, [history, success])

  const palceOrderHandler = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        // paymentMethod: cart.paymentMethod.name,
        itemPrice: cart.itemPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
      })
    )
  }

  return (
    <>
      <div className='container'>
        <div className='row justify-content-md-center'>
          <div className='col-xs-12 col-md-6'>
            <CheckoutSteps step1 step2 step3 step4 />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-8'>
          <ul className='list-group list-group-flush'>
            <li className='list-group-item'>
              <h2> Shipping to {userInfo?.user?.name}</h2>
              <p><strong>Address:</strong> {cart.shippingAddress.address}</p>
              <p>
                <strong>Phone Number: </strong>
                {cart.shippingAddress?.phoneNumber}
                {/* {cart.shippingAddress?.address}, {cart.shippingAddress?.city},{' '}
                {cart.shippingAddress?.postalCode},{' '}
                {cart.shippingAddress?.country} */}
              </p>
            </li>
            {/* <li className='list-group-item'>
              <h2>Payment Method</h2>
              <p>
                <strong>Method: </strong>
                {cart.paymentMethod.name}
              </p>
              <p>
                <strong>Account Number: </strong>
                {cart.paymentMethod?.account_number}
              </p>
            </li> */}
            <li className='list-group-item'>
              <h2>Order Items</h2>
              {cart.cartItems.length === 0 ? (
                <Message>Your cart is empty</Message>
              ) : (
                <ul className='list-group list-group-flush'>
                  {cart.cartItems.map((item, index) => (
                    <li className='list-group-item' key={index}>
                      <div className='row'>
                        <div className='col-md-1'>
                          <img
                            src={`${BASE_URL}${item.image}`}
                            alt={item.name}
                            className='img img-fluid rounded'
                          />
                        </div>
                        <div className='col'>
                          <Link
                            to={`/product/${item.product}`}
                            className='text-dark text-decoration-none'
                          >
                            {item.name}
                          </Link>
                        </div>
                        <div className='col-md-4'>
                          {item.qty} x {Math.floor(item.price).toLocaleString()} ={' '}
                          {Math.floor(item.qty * item.price).toLocaleString()}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        </div>
        <div className='col-md-4'>
          <div className='card'>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item'>
                <h2>Order Summary</h2>
              </li>
              <li className='list-group-item'>
                <div className='row'>
                  <div className='col'>Items</div>
                  <div className='col'>ETB {Math.floor(cart.itemPrice).toLocaleString()}</div>
                </div>
              </li>
              <li className='list-group-item'>
                <div className='row'>
                  <div className='col'>Shipping</div>
                  <div className='col'>Free</div>
                  {/* <div className='col'>ETB {cart.shippingPrice}</div> */}
                </div>
              </li>
              {/* <li className='list-group-item'>
                <div className='row'>
                  <div className='col'>Tax</div>
                  <div className='col'>ETB {cart.taxPrice}</div>
                </div>
              </li> */}
              <li className='list-group-item'>
                <div className='row'>
                  <div className='col'>Total</div>
                  <div className='col'>ETB {Math.floor(cart.totalPrice).toLocaleString()}</div>
                </div>
              </li>
              <li className='list-group-item'>
                {error && <Message variant='danger'>{error}</Message>}
              </li>
            </ul>
            <button
              type='button'
              className='btn btn-dark btn-block m-2'
              disabled={cart.cartItems === 0}
              onClick={palceOrderHandler}
            >
              Place Order
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PlaceOrderScreen
