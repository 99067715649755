import React, { useState } from 'react'

const SearchBox = ({ history }) => {
  const [keyword, setKeyword] = useState('')

  const submitHandler = (e) => {
    e.preventDefault()
    if (keyword.trim()) {
      history.push(`/search/${keyword}`)
    } else {
      history.push('/')
    }
  }
  return (
    <form onSubmit={submitHandler} className='search'>
      <input
        type='text'
        className='search__input'
        name='q'
        onChange={(e) => setKeyword(e.target.value)}
        placeholder='Search Products...'
      />
      <button class="search__button">
        <span class="search__icon">
          <i class="fas fa-search"></i>
        </span>
      </button>
    </form>
  )
}

export default SearchBox
