import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  getSubCategoriesWithCategoryId,
  getSubSubCategoriesWithCategoryId,
} from '../actions/categoryActions'
import { listProducts } from '../actions/productActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Product from '../components/Product'

function CategoryScreen({ match }) {
  const [products, setProducts] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  // const [price, setPrice] = useState(null)
  const categoryId = match.params.categoryId

  const getCategoryProducts = async (categoryId) => {
    const { data } = await axios.get(`/api/categories/${categoryId}/products`)
    if (data) {
      setProducts(data)
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSubSubCategoriesWithCategoryId(categoryId))
    getCategoryProducts(categoryId)
  }, [dispatch, categoryId])

  const subSubCategoriesList = useSelector(
    (state) => state.subSubCategoriesList
  )
  const { loading, error, subSubCategories: categories } = subSubCategoriesList

  const getProducts = async (category_id) => {
    const { data } = await axios.get(`/api/products?category_ids=${category_id}`)
    setProducts(data.data)
  }

  const handleCategorySelectChange = (e) => {
    if(e.target.value !== 'Select Category') {
      setSelectedCategory(e.target.value)
      getProducts(selectedCategory)
    } else {
      setSelectedCategory(null)
    }
  }

  return (
    <div>
      <h2>Category Name</h2>
        <div className='container row mb-3'>
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant='danger'>{error}</Message>
                  ) : (
                    categories.length > 0 && (                    
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Select Category</label>
                          <select className='form-control' onChange={(e) => handleCategorySelectChange(e)}>
                            <option>Select Category</option>
                            {categories.map((category) => (
                              <option key={category._id} value={category.category_id}>{category.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )
                    // categories.map((category) => (
                      // <button
                      //   key={category.category_id}
                      //   className={`nav-link ${
                      //     category.category_id == categoryId ? 'active' : ''
                      //   }`}
                      //   id={`nav-${category.name}-tab`}
                      //   data-bs-toggle='tab'
                      //   data-bs-target={`#nav-${category.name}`}
                      //   type='button'
                      //   role='tab'
                      //   aria-controls={`nav-${category.name}`}
                      //   aria-selected={
                      //     category.category_id === categoryId ? 'true' : 'false'
                      //   }
                      //   onClick={() => getProducts(category.category_id)}
                      // >
                      //   {category.name}
                      // </button>
                    // ))
                  )}
                  {/* <div className='col-md-6'>
                    <div className='form-group'>
                      <label>Price</label>
                      <select className='form-control' onChange={(e) => handlePriceSelectChange(e)}>
                        <option>Select Price Range</option>
                        <option value='0-100'>0-100</option>
                        <option value='100-500'>100-500</option>
                        <option value='500-1000'>500-1000</option>
                        <option value='1000-5000'>1000-5000</option>
                        <option value='5000-10000'>5000-10000</option>
                        <option value='10000-50000'>10000-50000</option>
                      </select>
                    </div>
                  </div> */}
              </div>
              <div className='row'>
                {products.length > 0 ? (
                  products.map((product) => (
                    <div
                      key={product.product_id}
                      className='col-sm-3 col-md-3 col-lg-3 col-xl-3'
                    >
                      <Product product={product} />
                    </div>
                  ))
                ) : (
                  <div className='card card-body card-info'>
                    <h4>No Product Found</h4>
                  </div>
                )}
                </div>
              </div>
  )
}

export default CategoryScreen
