import React from 'react'
import '../css/online-order-section.css'
import { useSelector } from 'react-redux'

const OnlineOrderSection = ({history}) => {

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const handleOrderNowClick = () => {
        if(userInfo != null) {
           // Redirect the user to online order screen
           history.push('/onlineorder')
        } else {
            // Redirect the user to login page
            history.push('/login?redirect=onlineorder')
        }
    }

    return (
        <div className="container online-order-container">
          <div className="row">
            <div className="col-lg-6 online-order-text">            
                <h1 className="online-order-text-title">Directly from your favorite store to you.</h1>
                <div className='online-order-text-input-container'>
                    <input className='online-order-text-input' placeholder='Paste Your Link'  />
                </div>

                <div className='online-order-text-btn-container'>               
                    <button type="submit" className="btn btn-order" onClick={handleOrderNowClick}>Order Now</button>
                </div>
            </div>
            <div className="col-lg-6 online-order-image">
              <img src="/blackfriday.png" className="img-fluid" alt="Jeba Mega Store Black Friday" />
            </div>
          </div>
        </div>
      );
}

export default OnlineOrderSection