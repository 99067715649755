export const GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_REQUEST =
  'GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_REQUEST'
export const GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_SUCCESS =
  'GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_SUCCESS'
export const GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_FAIL =
  'GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_FAIL'

export const GET_SUB_CATEGORIES_WITH_CATEGORY_ID_REQUEST =
  'GET_SUB_CATEGORIES_WITH_CATEGORY_ID_REQUEST'
export const GET_SUB_CATEGORIES_WITH_CATEGORY_ID_SUCCESS =
  'GET_SUB_CATEGORIES_WITH_CATEGORY_ID_SUCCESS'
export const GET_SUB_CATEGORIES_WITH_CATEGORY_ID_FAIL =
  'GET_SUB_CATEGORIES_WITH_CATEGORY_ID_FAIL'

export const GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_REQUEST =
  'GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_REQUEST'
export const GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_SUCCESS =
  'GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_SUCCESS'
export const GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_FAIL =
  'GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_FAIL'

export const GET_CATEGORY_PRODUCTS_REQUEST = 'GET_CATEGORY_PRODUCTS_REQUEST'
export const GET_CATEGORY_PRODUCTS_SUCCESS = 'GET_CATEGORY_PRODUCTS_SUCCESS'
export const GET_CATEGORY_PRODUCTS_FAIL = 'GET_CATEGORY_PRODUCTS_FAIL'

export const GET_ALL_CATEGORIES_LIST_REQUEST = 'GET_ALL_CATEGORIES_LIST_REQUEST'
export const GET_ALL_CATEGORIES_LIST_SUCCESS = 'GET_ALL_CATEGORIES_LIST_SUCCESS'
export const GET_ALL_CATEGORIES_LIST_FAIL = 'GET_ALL_CATEGORIES_LIST_FAIL'
