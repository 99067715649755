import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { currentOrder, listMyOrders } from '../actions/orderActions'
import { Link } from 'react-router-dom'
import { BASE_URL } from '../constants/globalConstants'
import OrderStepsIndicator from '../components/OrderStepsIndicator'

const CurrentOrderScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      dispatch(currentOrder())
    }
  }, [history, userInfo, dispatch])

  const myCurrentOrder = useSelector((state) => state.currentOrder)
  const { loading, error, order } = myCurrentOrder

  const orderSteps = ['Order Placed', 'Processing', 'Delivering', 'Delivered'];
  const currentStep = 3;

  const formatDate = (timestamp) => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(timestamp);
    date.setDate(date.getDate() + 7);
    const day = daysOfWeek[date.getDay()];
    return `${day}, ${date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })}`;
  };

  return (
    <div className='container'>

      {loading ? (<Loader />)
        : error ? (<Message variant='danger'>{error}</Message>) : order != null ? (
          <>
            <div className='d-flex justify-content-between align-items-center'>
              <h3>
                Arriving {formatDate(order.created_at)}
              </h3>
              <Link to={`/orders`}>
                <strong>See all orders</strong>
              </Link>
            </div>
            <div className='card card-body'>
              <ul className='d-flex flex-nowrap'>
                {order?.order_items?.map((item, index) => (
                  <>
                    <li className='list-group-item' key={index}>
                      <Link to={`/product/${item.product_id}`} className=''>
                        <img
                          // eslint-disable-next-line no-undef
                          // src={`https://www.volusion.com/blog/content/images/2021/09/Product-Photography-1.jpeg`}
                          src={`${BASE_URL}${item.product.image}`}
                          // 
                          alt={item.product.name}
                          height={`100px`}
                          width={`100px`}
                          className='img img-fluid rounded'
                        />
                      </Link>
                    </li>
                  </>
                ))}
              </ul>
              <hr />
              <div className='text-center'>
                <h4>Ordered</h4>
                <div className='mb-4'>
                  <OrderStepsIndicator steps={orderSteps} currentStep={order?.order_status?.name == 'Delivered' ? 4 : (order?.order_status?.name == 'Delivery On Progress' ? 3 : 2)} />
                </div>
                <button className='btn btn-light'>Update delivery instruction</button>
              </div>
              <hr />

              <div>
                <h5>Shipping Address</h5>
                <p>Name {userInfo?.user?.name}</p>
                <p>Phone number: {order?.shipping_address?.phone_number}</p>
                <p>Address: {order?.shipping_address?.address}</p>
              </div>

              <hr />

              <div>
                <h5>Order Info</h5>
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <Link to={`/order/${order.order_id}`}>
                      View or Change this order
                    </Link>
                    <p>{'>'}</p>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <Link to={`/order/${order.order_id}`}>
                      Cancel order
                    </Link>
                    <p>{'>'}</p>
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          <div className='card card-body'>
            No Order Found
          </div>
        )
      }
    </div>
  )
}

export default CurrentOrderScreen