import React from 'react';
import FormContainer from '../components/FormContainer'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux';
import { createOnlineOrder } from '../actions/orderActions';
import Message from '../components/Message';
import Loader from '../components/Loader';

const OnlineOrderScreen = () => {

  const dispatch = useDispatch()

  const onlineOrderCreate = useSelector((state) => state.onlineOrderCreate)
  const {loading, success, error } = onlineOrderCreate

  const { register, handleSubmit, formState: { errors }, control } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'links',
  });

  const submitHandler = (data) => {
    const links = data.links.map((link) => link.url).join(',') // Concatenate links with commas
    const formData = { ...data, links }
    console.log(formData)
    // Send this formData object to the backend
    dispatch(createOnlineOrder(formData))
  };

  return (
    <FormContainer>
      <h1>Directly from your favirote store to you.</h1>
      {loading && <Loader />}
      {error && <Message variant='danger'>{error}</Message>}
      {success && <Message variant='success'>Thank you for your order! Your order has been successfully saved. We'll contact you.</Message>}
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className='form-group'>
          <label htmlFor='name'>Name</label>
          <input
            type='name'
            className={`form-control my-1 ${errors.name && 'is-invalid'}`}
            placeholder='Enter name'
            {...register('name', { required: true })}
          />
          {errors.name && <span className='invalid-feedback'>This name field is required</span>}
        </div>
        <div className='form-group'>
          <label htmlFor='phone_number'>Phone Number</label>
          <input
            type='number'
            className={`form-control my-1 ${errors.phoneNumber && 'is-invalid'}`}
            placeholder='Phone Number'
            maxLength='10'
            {...register('phoneNumber', { required: true, type: 'number' })}
          />
          {errors.phoneNumber && (
            <span className='invalid-feedback'>This phone number field is required</span>
          )}
        </div>
        <div className='form-group'>
          <div className='d-flex justify-content-between m-2'>
            <label htmlFor='links'>Links</label>
            <button type='button' className='btn btn-dark btn-sm' onClick={() => append({})}>
              Add New
            </button>
          </div>
          {fields.map((field, index) => (
            <div key={field.id} className='d-flex'>
              <input
                type='text'
                className={`form-control my-1 ${
                  (errors.links &&
                    errors.links[index] &&
                    errors.links[index].url) &&
                  'is-invalid'
                }`}
                placeholder='Enter link'
                {...register(`links.${index}.url`, { required: true })}
                defaultValue={field.url}
              />
              {index !== 0 && (
                <div className='mt-1 p-1'>
                  <button type='button' className='btn btn-sm btn-danger' onClick={() => remove(index)}>
                  X
                </button>
                </div>
              )}
            </div>
          ))}
          {fields.length === 0 && (
            <input
              type='text'
              className={`form-control my-1 ${
                  (errors.links &&
                    errors.links[0] &&
                    errors.links[0].url) &&
                  'is-invalid'
                }`}
              placeholder='Enter link'
              {...register('links.0.url', { required: true })}
            />
          )}         
        </div>
        <div className='form-group'>
        <label htmlFor='message'>Message (Optional)</label>
        <Controller
          control={control}
          name='message'
          render={({ field }) => (
            <textarea
              className='form-control'
              placeholder='Enter message'
              {...field}
            />
          )}
        />
      </div>      
        <button type='submit' className='btn btn-dark my-2'>Submit</button>
      </form>
    </FormContainer>
  );
};

export default OnlineOrderScreen;