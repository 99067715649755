import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserDetails, updateUserProfile } from '../actions/userActions'

const ProfileScreen = ({ location, history }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirm_password, setConfirmPassword] = useState('')
  const [phone_number, setPhoneNumber] = useState('09')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (!user.name) {
        dispatch(getUserDetails())
      } else {
        setName(user.name)
        setEmail(user.email)
        setPhoneNumber(user.phone_number)
      }
    }
  }, [dispatch, history, userInfo, user])

  const submitHandler = (e) => {
    e.preventDefault()

    // if (password !== confirm_password) {
    //   setMessage('Password do not match')
    // } else {
    dispatch(updateUserProfile({ id: user.id, name, email, password }))
    // }
  }
  return (
    <div className='row'>
      <div className='col-md-12'>
        <h2>User Profile</h2>
        {message && <Message variant='danger'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        {success && <Message variant='success'>Profile Updated</Message>}
        {loading && <Loader />}
        <form onSubmit={submitHandler}>
          <div className='form-group'>
            <label htmlFor='name'>Full Name</label>
            <input
              type='name'
              className='form-control my-1'
              placeholder='Enter name'
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='email'>Email Address</label>
            <input
              type='email'
              className='form-control my-1'
              placeholder='Enter email'
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='password'>Password</label>
            <input
              type='password'
              className='form-control my-1'
              placeholder='Enter password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='confirm_password'>Password</label>
            <input
              type='password'
              className='form-control my-1'
              placeholder='Confirm password'
              value={confirm_password}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='phone_number'>Phone Number</label>
            <input
              type='number'
              className='form-control my-1'
              placeholder='Phone Number'
              value={phone_number}
              maxLength='10'
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <button className='btn btn-dark my-2'>Update</button>
        </form>
      </div>
    </div>
  )
}

export default ProfileScreen
