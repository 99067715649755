import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  listCategories,
  listSubCategories,
  createProduct,
} from '../actions/productActions'

const CreateProductScreen = ({ history }) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [brand, setBrand] = useState('')
  const [price, setPrice] = useState('')
  const [countInStock, setCountInStock] = useState('')
  const [category, setCategory] = useState('')
  const [subCategory, setSubCategory] = useState('')
  const [image, setImage] = useState(null)

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productCreate = useSelector((state) => state.productCreate)
  const { loading, success, error } = productCreate

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      dispatch(listCategories())
    }
  }, [history, userInfo, dispatch, success, error])

  const categoryList = useSelector((state) => state.categoryList)
  const {
    loading: loadingCategories,
    error: errorCategories,
    categories,
  } = categoryList

  const onChangeCategory = (e) => {
    const categoryId = e.target.value
    if (categoryId) {
      setCategory(categoryId)
      // Load sub categories
      dispatch(listSubCategories(categoryId))
    }
  }

  const subCategoryList = useSelector((state) => state.subCategoryList)
  const { loadingSubCategories, errorSubCategories, subCategories } =
    subCategoryList
  const submitHandler = (e) => {
    e.preventDefault()

    const product = {
      name,
      description,
      brand,
      sub_category: subCategory,
      price,
      count_in_stock: countInStock,
      image,
    }

    dispatch(createProduct(product))
  }
  return (
    <div>
      <h1>Create a Product</h1>
      {success && (
        <Message variant='success'>Product created successfully.</Message>
      )}
      <form onSubmit={submitHandler}>
        <div className='row'>
          <div className='col-md-6'>
            {loadingCategories ? (
              <Loader />
            ) : errorCategories ? (
              <Message variant='danger'>{errorCategories}</Message>
            ) : (
              <div className='form-group'>
                <label htmlFor='category'>Category</label>
                <select
                  onChange={(e) => onChangeCategory(e)}
                  value={category}
                  className='form-control'
                  required
                >
                  <option value=''>Select Category</option>
                  {categories.map((category) => (
                    <option
                      value={category.category_id}
                      key={category.category_id}
                    >
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {loadingSubCategories ? (
              <Loader />
            ) : errorSubCategories ? (
              <Message variant='danger'>{errorSubCategories}</Message>
            ) : (
              <div className='form-group'>
                <label htmlFor='category'>Sub Category</label>
                <select
                  onChange={(e) => setSubCategory(e.target.value)}
                  value={subCategory}
                  className='form-control'
                  required
                >
                  <option value=''>Select Sub Category</option>
                  {subCategories.map((sub_category) => (
                    <option
                      value={sub_category.sub_category_id}
                      key={sub_category.sub_category_id}
                    >
                      {sub_category.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className='form-group'>
              <label htmlFor='name'>Name</label>
              <input
                type='text'
                placeholder='Product Name'
                className='form-control'
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='brand'>Brand</label>
              <input
                type='text'
                placeholder='Brand'
                className='form-control'
                onChange={(e) => setBrand(e.target.value)}
                value={brand}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='price'>Price</label>
              <input
                type='number'
                step='any'
                placeholder='Price'
                className='form-control'
                onChange={(e) => setPrice(e.target.value)}
                value={price}
                required
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='countInStock'>Count in Stock</label>
              <input
                type='number'
                step='any'
                placeholder='Count in Stock'
                className='form-control'
                onChange={(e) => setCountInStock(e.target.value)}
                value={countInStock}
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='image'>Upload an Image</label>
              <input
                type='file'
                onChange={(e) => setImage(e.target.files[0])}
                className='form-control'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='description'>Description</label>
              <textarea
                cols='30'
                rows='3'
                className='form-control'
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                required
              ></textarea>
            </div>
            <div className='d-flex justify-content-end'>
              <button className='btn btn-dark my-4'>Save</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateProductScreen
