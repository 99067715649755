import React from 'react'
import { Link } from 'react-router-dom'
import { BASE_URL } from '../constants/globalConstants'
import Rating from './Rating'

const Product = ({ product }) => {
  console.log('count_in_stock', product.count_in_stock)
  return (
    <Link to={`/product/${product.product_id}`} className='product-card'>
      {/* <Link to={`/product/${product._id}`}> */}
        <img src={`${BASE_URL}${product.image}`} alt={product.name} className='product-card__image' />
      {/* </Link> */}
        <div className='product-card__name'>
          <strong>{product.name}</strong>
        </div>
        <div className='produc-card__review'>
          <Rating
            value={product.rating}
            text={`${
              product.num_of_reviews == null ? '0' : Math.round(product.num_of_reviews)
            } reviews`}
          />
        </div>
        {product.count_in_stock <= 5 && <p className='product-card__count'>Status:{' '}
                    {product.count_in_stock == 0 ? 'Out of Stock' :  product.count_in_stock <= 5 ? `Only ${product.count_in_stock} in stock - order soon` : "" }</p>}
        
        <p className='product-card__price'>ETB {Math.floor(product.price).toLocaleString()}</p>

    </Link>
  )
}

export default Product
