import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { registerUser } from '../actions/userActions'
import { useForm } from 'react-hook-form'

const RegisterScreen = ({ location, history }) => {

  const [message, setMessage] = useState(null)

  const { register, handleSubmit, formState: { errors } } = useForm();

  const dispatch = useDispatch()

  const userRegister = useSelector((state) => state.userRegister)

  const { loading, error, userInfo } = userRegister

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  const submitHandler = (data) => {
    if (data.password !== data.confirmPassword) {
      setMessage('Password do not match')
    } else {
      dispatch(registerUser(data.name, data.email, data.password, data.phoneNumber))
    }
  }
  return (
    <FormContainer>
      <h1>Sign Up</h1>
      {message && <Message variant='danger'>{message}</Message>}
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className='form-group'>
          <label htmlFor='name'>Full Name</label>
          <input
            type='name'
            className={`form-control my-1 ${errors.name && 'is-invalid'}`}
            placeholder='Enter name'
            // value={name}
           {...register('name', {required: true})}
            // onChange={(e) => setName(e.target.value)}
          />
          {errors.name && <span className='invalid-feedback'>This name field is required</span>}
        </div>
        <div className='form-group'>
          <label htmlFor='email'>Email Address</label>
          <input
            type='email'
            className={`form-control my-1 ${errors.email && 'is-invalid'}`}
            placeholder='Enter email'
            // value={email}
            {...register('email', {required: true, type: 'email'})}
            // onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <span className='invalid-feedback'>This email field is required</span>}
        </div>
        <div className='form-group'>
        <label htmlFor='password'>Password</label>
          <input
            type='password'
            className={`form-control my-1 ${errors.password && 'is-invalid'}`}
            placeholder='Enter password'
            // value={password}
            // onChange={(e) => setPassword(e.target.value)}
            {...register('password', {required: true})}
          />
          {errors.password && <span className='invalid-feedback'>This password field is required</span>}
        </div>
        <div className='form-group'>
        <label htmlFor='confirmPassword'>Confirm Password</label>
        <input
            type='password'
            className={`form-control my-1 ${errors.confirmPassword && 'is-invalid'}`}
            placeholder='Enter password'
            // value={password}
            // onChange={(e) => setPassword(e.target.value)}
            {...register('confirmPassword', {required: true})}
          />
          {errors.confirmPassword && <span className='invalid-feedback'>This confirm password field is required and must be the same as password</span>}
        </div>
        <div className='form-group'>
          <label htmlFor='phone_number'>Phone Number</label>
          <input
            type='number'
            className={`form-control my-1 ${errors.phoneNumber && 'is-invalid'}`}
            placeholder='Phone Number'
            // value={phone_number}
            maxLength='10'
            // onChange={(e) => setPhoneNumber(e.target.value)}
            {...register('phoneNumber', {required: true, type: 'number'})}
          />
          {errors.phoneNumber && <span className='invalid-feedback'>This phone number field is required</span>}
        </div>
        <button className='btn btn-dark my-2'>Register</button>
        <div className='row py-3'>
          <div className='col'>
            Have an account?{' '}
            <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
              Login
            </Link>
          </div>
        </div>
      </form>
    </FormContainer>
  )
}

export default RegisterScreen
