import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listMyOrders } from '../actions/orderActions'
import { Link } from 'react-router-dom'
import { BASE_URL } from '../constants/globalConstants'
import OrderStepsIndicator from '../components/OrderStepsIndicator'

const OrderListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      dispatch(listMyOrders())
    }
  }, [history, userInfo, dispatch])

  const orderList = useSelector((state) => state.orderList)
  const { loading, error, orders } = orderList

  const orderSteps = ['Order Placed', 'Processing', 'Delivering', 'Delivered'];
  const currentStep = 3;

  return (
    <>
      <h3>My Orders</h3>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <div>
          {orders.length > 0 ? <h4 className={'alert alert-success'}>
            Thank you! your order has been successfully placed and is now being processed.
          </h4> : <h4>No Order Found.</h4>}
          {orders.map((order) => (
            <div className='card m-2' key={order.order_id}>
              <Link to={`/order/${order.order_id}`} className='card-body'>
                <OrderStepsIndicator steps={orderSteps} currentStep={order?.order_status?.name == 'Delivered' ? 4 : (order?.order_status?.name == 'Delivery On Progress' ? 3 : 2)} />
                <p>Order Date: {new Date(order.created_at).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                <p>Order Number: {order.order_id.slice(0, 8).toUpperCase()}</p>
                <p>Order Total: {order.total_price}</p>
                <p>Shipping to {userInfo?.user?.name}, {order.shipping_address.phone_number} {` `}
                  {order.shipping_address.address}</p>
                {/* Don't forget to add order status indicator bar */}
                <ul className='d-flex flex-nowrap'>
                  {order?.order_items.map((item, index) => (
                    <li className='list-group-item' key={index}>
                      <Link to={`/product/${item.product_id}`} className=''>
                        <img
                          // eslint-disable-next-line no-undef
                          // src={`/product/${item.product}`}
                          src={`${BASE_URL}${item.product.image}`}
                          // 
                          alt={item.product.name}
                          height={`100px`}
                          width={`100px`}
                          className='img img-fluid rounded'
                        />
                      </Link>
                    </li>
                  ))}
                </ul>
                <div className='float-end'>
                  {/* <button className='btn btn-sm btn-danger'>Cancel</button> */}
                </div>
              </Link>
              {/* <td>{order.order_id}</td>
                <td>{order.total_price}</td>
                <td>{order.is_paid === true ? 'Paid' : 'Unpaid'}</td>
                <td>{order.is_delivered === true? 'Delivered' : 'Not Delivered'}</td>
                <td>{order?.order_status?.name}</td> */}
              {/* <LinkContainer to={`/order/${order.order_id}`}>
                    <button className='btn btn-dark btn-sm'>Details</button>
                  </LinkContainer> */}
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default OrderListScreen
